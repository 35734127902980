


.subtitle-policy {
    /* height: 4%; */
    padding: 20px;
    color: var(--font-color)
}

.subtitle-policy {
    /* width: 100%;  */
    height: 2%;
    padding: 23px;
    /* margin: 0 auto; */
    margin-top: 6%;
    text-align: center;
    color: var(--primary-color-th);
    font-weight: 600;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 2360px) and (min-device-height: 768px) {
    .subtitle-policy {
        /* width: 100%;  */
        height: 2%;
        padding: 23px;
        /* margin: 0 auto; */
        margin-top: 6%;
        text-align: center;
        color: var(--primary-color-th);
        font-weight: 600;
        font-size: 1.5rem;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 2360px) and (orientation: landscape) and (min-device-height: 768px) {
    .subtitle-policy {
        display: none;
    }
}

.permission_policy {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background-color: var(--background-color);
    z-index: 999;
    overflow-y: auto;
    padding: 0px;
    box-sizing: border-box;
}

.title {
    /* height: 0%; */
    /* font-size: 12px; */
}

.policy-open {
    background-color: #fff;
    height: calc(40svh - 40px);
    overflow: scroll;
    overflow-x: hidden;
}

.title-policy {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    /* padding: 10px; */
    padding-left: 20px;
    padding-right: 20px;
}

.paragrafo-policy {
    font-size: 14px;
    text-align: left;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
}


.accept-container {
    text-align: center;
    font-size: 16px;
    background-color: var(--background-button-color);
    margin: 0 auto;
    color: #fff;
    line-height: 45px;
    margin-top: 30px;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 8%;
    text-transform: uppercase;
}

.poweredbyARShades {
    font-size: 0.7rem;
    font-weight: bold;
    color: var(--font-color);
}

.poweredby {
    font-size: 0.7rem;
    position: absolute;
    /* bottom: 10px; */
    bottom: 5%;
    left: 50%;
    color: var(--font-color);
    transform: translate(-50%, 0);
}

@media only screen and (min-device-width: 768px) and (max-device-width: 2360px) and (orientation: landscape) and (min-device-height: 768px) {
    .accordion-container {
        width: 50%;
    }

    .accordion-wrapper {
        position: relative;
        top: 15%;
    }
}