:root {
  --primary-color: #375070;
  --secondary-color: #86ACCA;
  --background-permis_policy: #ffffff;
  --color_button_permis_policy_noread: #375070;
  --color_button_permis_policy_read: #86ACCA;
  --color_arrow: #375070;
  --background-button-color: #00174F;
  --font-button-color: rgba(255, 255, 255, 1);
  /* Costanti per PermissionAndPolicy */
  --color-permis_policy_border: #000000;
}

/* Struttura base della pagina */
.snapshot-container {
  display: flex;
  flex-direction: column;
  height: 100svh; /* Utilizza le unità di viewport modern (Safari 16+): svh */
  font-family: Arial, sans-serif;
  margin: 0;
}

/* HEADER */
.header {
  height: 18svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  padding-top: 20px;
  margin-bottom: 10px;
}

.logo-image {
  width: 150px;
  height: auto;
  display: block;
}

.snapshot-subtitle {
  color: var(--primary-color);
  font-size: 1.2rem;
  font-weight: 600;
  margin: 10px;
  padding: 0px;
}

/* PHOTO CONTAINER */
.photo-container {
  height: 40svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.snapshot-title {
  color: var(--primary-color);
  font-size: 1.3rem;
  font-weight: 700;
  margin: 10px;
  padding: 0px;
  text-transform: uppercase;
}

.image-wrapper {
  width: 90%;
  max-width: 400px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
}

/* L'immagine vera e propria */
.snapshot-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* oppure cover, a seconda dello stile che vuoi */
}

/* Classi per modificare ulteriormente in base all'orientamento */
.landscape {
  /* Se vuoi dare uno stile diverso alle immagini orizzontali, puoi intervenire qui */
}

.portrait {
  /* E qui per quelle verticali */
}

/* FOOTER */
.footer {
  /* height: 35svh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  padding: 10px;
}

/* Pulsante SHARE */
.button-vanilla {
  all: unset; /* rimuove ogni stile di base */
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  margin: 23px auto;
  text-align: center;
  cursor: pointer;
  border: none;
}

.btn-text-vanilla {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 45px;
  padding: 5px 0;
  border: 2px solid var(--primary-color);
}

.share-icon {
  width: 26px;   /* dimensione a piacere */
  margin-right: 8px; /* un piccolo distacco dal testo */
    filter: invert(18%) sepia(90%) saturate(1476%) hue-rotate(185deg) brightness(88%) contrast(104%);

}


.CTA-TEXT-vanilla {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5px;
}

.footer-two {
  margin-top: auto; /* Spinge il footer verso il basso */
  text-align: center;
  padding: 20px;
}

.snapshot-info-three {
  margin: 5px 0;
  font-size: 14px;
  color: var(--primary-color);
}

.privacy-link {
  color: var(--primary-color);
  text-decoration: underline;
}

.privacy-link:hover {
  text-decoration: underline;
}

.powered-by {
  font-size: 12px;
  color: #888;
}

.snapshot-info {
  text-decoration: underline; 
  margin: 5px;
  font-weight: 700;
  font-size: 1.0rem;
  color: var(--primary-color);

  /* padding: 5px; */
  /* Aggiungi qui altri stili desiderati */
}

.snapshot-info-two {
  /* padding: 5px; */
  font-weight: 400;
  font-size: 0.9rem;
  color: var(--primary-color);

  /* Aggiungi qui altri stili desiderati */
}

.snapshot-info-three {
  /* padding: 5px; */
  /* Aggiungi qui altri stili desiderati */
}
/* Esempio di media query orizzontale */
@media only screen and (min-device-width: 768px) and (max-device-width: 2360px) 
  and (orientation: landscape) and (min-device-height: 768px) {
  
  .button-vanilla {
    width: 60%;
  }

  .CTA-TEXT-vanilla {
    font-size: 24px;
    font-weight: 400;
  }

  .btn-text-vanilla {
    height: 70px;
  }
}
