/* Contenitore principale dell'accordion */
.accordion-container {
    width: 90%;
    margin: 40px auto;
    border: 2px solid var(--color-permis_policy_border);
    /* Colore di default */
    background-color: var(--background-permis_policy);
    /* Sfondo di default */
    transition: all 0.3s ease;
    overflow: hidden;
    /* Impedisce overflow laterali */
}

/* Header dell'accordion */
.accordion-header {
    padding: 10px 20px;
    background-color: var(--background-permis_policy);
    /* Mantieni il colore specifico */
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

/* Wrapper del titolo */
.accordion-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Titolo */
.accordion-title {
    font-family: var(--font-family);
    /* Font di default */
    font-size: 18px;
    font-weight: 300;
    /* Peso di default */
    color: var(--primary-color);
    /* Nero di default */
    margin: 0;
    transition: color 0.3s ease;
    /* Transizione per il cambiamento di colore */
}

/* Titolo attivo */
.accordion-title.active-title {
    /* color: ; */
}

/* Testo READ e icona */
.accordion-read {
    font-family: "Gill Sans-Regular", Helvetica, Arial, sans-serif;
    /* Font di default */
    font-size: 16px;
    font-weight: 400;
    color: var(--primary-color-th);
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
    /* Transizione per il cambiamento di colore */
}

/* Testo READ attivo */
.accordion-read.active-read {
    color: var(--color_button_permis_policy_read);
}

/* Freccia */
.accordion-arrow {
    display: inline-block;
    margin-left: 10px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--color_arrow);
    /* Nero di default */
    transition: border-color 0.3s ease, transform 0.3s ease;
}

/* Freccia attiva (verde) */
.accordion-arrow.active-arrow {
    border-top-color: var(--primary-color-th);
}

/* Ruota la freccia quando l'accordion è aperto */
.accordion-arrow.rotate {
    transform: rotate(180deg);
}

/* Contenuto dell'accordion */
.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    padding: 0 20px;
    font-family: var(--font-family);
    /* Font di default */
    font-size: 14px;
    line-height: 1.5;
    background-color: var(--background-permis_policy);
    /* Sfondo di default */
}

.accordion-container.expanded .accordion-content {
    max-height: 500px;
    /* Dimensione massima per il contenuto */
    padding: 20px;
    /* Spazi interni */
}

@media only screen and (max-device-width: 767px) {
    .accordion-container.expanded .accordion-content {
        padding: 5px;
        /* Spazi interni */
    }
}

/* Media Queries per dispositivi mobili */
@media only screen and (max-width: 480px) {
    .accordion-container {
        width: 85%;
    }

    .accordion-title {
        font-size: 16px;
    }

    .accordion-read {
        font-size: 14px;
        font-weight: 600;
    }

    .accordion-arrow {
        border-top: 6px solid var(--color_arrow);
    }
}

/* Vanilla v2 */

.v2-title_policy_accordion {
    font-size: 16px;
    color: #888888;
    line-height: 50px;
}

@media only screen and (max-width: 480px) {
    .v2-title_policy_accordion {
        line-height: 40px;
    }
}

@media only screen and (max-width: 780px) {
    .v2-title_policy_accordion {
        line-height: 40px;
    }
}

.v2-accordion-close {
    width: 40%;
    background-color: #f1f1f1;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.v2-accordion-close p {
    margin: 0px;
}

@media only screen and (max-width: 480px) {
    .v2-accordion-close {
        width: 90%;
    }
}

@media only screen and (max-width: 780px) {
    .v2-accordion-close {
        width: 90%;
    }
}

.v2-flex {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.v2-left-accordion-policy {
    margin-left: 20px;
}

.v2-read-policy {
    font-size: 18px;
    color: var(--primary-color);
    line-height: 50px;
    font-weight: 400;
}

@media only screen and (max-width: 480px) {
    .v2-read-policy {
        line-height: 40px;
    }
}

@media only screen and (max-width: 780px) {
    .v2-read-policy {
        line-height: 40px;
    }
}

.v2-right-accordion-policy {
    margin-right: 20px;
}

.v2-arrow-icon {
    display: inline-block;
    margin-left: 7px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    /* Dimensioni della freccia */
    border-right: 6px solid transparent;
    border-top: 6px solid var(--color_arrow);
    /* Colore nero */
    transition: transform 0.3s ease;
    /* Transizione per la rotazione */
}

.v2-arrow-icon.rotate {
    transform: rotate(180deg);
    /* Ruota la freccia verso l'alto quando il pannello è aperto */
}